import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { CommonImages, InstructionScreen, VideosByStage } from "Assets/Common";
import { StageOneImages } from "Assets/StageOne";
import {
  ChessPuzzleImages,
  CrosswordImages,
  DrawTheKingImages,
  StageTwoImages,
} from "Assets/StageTwo";
import ProgressBar from "react-bootstrap/ProgressBar";

import "./Loader.scss";
import { ROUTES } from "Constants/Common";
import { MazeImages } from "Assets/StageFour";

function Loader() {
  const navigate = useNavigate();
  // Function to traverse the object and collect URLs
  const getAllUrls = useCallback((obj) => {
    const result = [];
    function traverse(obj) {
      for (const key in obj) {
        if (typeof obj[key] === "string") {
          result.push(obj[key]);
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          traverse(obj[key]);
        }
      }
    }
    traverse(obj);
    return result;
  }, []);

  useEffect(() => {
    async function loadResources() {
      const stageOneImageUrls = getAllUrls({
        ...CommonImages,
        ...StageOneImages,
        ...InstructionScreen,
      });

      const otherImageUrls = getAllUrls({
        ...StageTwoImages,
        ...CrosswordImages,
        ...ChessPuzzleImages,
        ...DrawTheKingImages,
        ...MazeImages,
      });

      const stageOneVideoUrls = getAllUrls(VideosByStage.one);

      const otherVidesUrl = getAllUrls({
        two: VideosByStage.two,
        three: VideosByStage.three,
      });

      const imagePromises = stageOneImageUrls.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = () => reject(new Error(`Failed to load ${src}`));
        });
      });

      const videoPromises = stageOneVideoUrls.map((src) => {
        return new Promise((resolve, reject) => {
          const video = document.createElement("video");
          video.src = src;

          const onLoaded = () => {
            cleanup();
            resolve();
          };

          const onError = () => {
            cleanup();
            reject(new Error(`Failed to load ${src}`));
          };

          const cleanup = () => {
            video.removeEventListener("canplaythrough", onLoaded);
            video.removeEventListener("error", onError);
          };

          video.addEventListener("canplaythrough", onLoaded);
          video.addEventListener("error", onError);
        });
      });

      // const otherImagePromises =
      otherImageUrls.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = () => reject(new Error(`Failed to load ${src}`));
        });
      });

      // const videoPromises =
      otherVidesUrl.map((src) => {
        return new Promise((resolve, reject) => {
          const video = document.createElement("video");
          video.src = src;

          const onLoaded = () => {
            cleanup();
            resolve();
          };

          const onError = () => {
            cleanup();
            reject(new Error(`Failed to load ${src}`));
          };

          const cleanup = () => {
            video.removeEventListener("canplaythrough", onLoaded);
            video.removeEventListener("error", onError);
          };

          video.addEventListener("canplaythrough", onLoaded);
          video.addEventListener("error", onError);
        });
      });

      try {
        await Promise.all([...imagePromises, ...videoPromises]);

        navigate(ROUTES.LOGIN, {
          state: {
            eventId: new URLSearchParams(window.location.search).get(
              "event_id",
            ),
            roomCode: new URLSearchParams(window.location.search).get(
              "game_code",
            ),
            eventCode: new URLSearchParams(window.location.search).get("code"),
            teamId: new URLSearchParams(window.location.search).get("team_id"),
          },
        });
      } catch (error) {
        console.error(error);
        alert("Failed to load resources. Please try again.");
      }
    }

    loadResources();
  }, [getAllUrls, navigate]);

  // Timeout to show an error message if the loading takes too long
  useEffect(() => {
    const timeout = setTimeout(() => {
      // reload the page if the loading takes too long
      window.location.reload();
    }, 30 * 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="loader">
      <div className="loader_overlay"></div>
      <div className="loader_content">
        <img src={CommonImages.logo} alt="Logo" className="loader_logo" />
        <ProgressBar animated now={100} striped variant="warning" />
        <h1>Loading...</h1>
      </div>
    </div>
  );
}

export default Loader;
